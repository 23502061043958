import qs from 'qs';
import {
  setCIDCookie,
  setPARTNERCookie,
  setSSIDCookie,
  setOfxFCookie,
  setReferralCodeCookie,
  expireReferralCodeCookie,
} from './cookies';

export interface Referrals {
  cid?: string;
  pid?: string;
  ssid?: string;
  ofxf?: string;
  referralCode?: string;
}

export const getReferrals = (query: string): Referrals => {
  return qs.parse(query);
};

export const setReferralCookies = (
  data: Referrals,
  expireReferralCodeCookieWhenNotInQuery: boolean
): void => {
  if (data.cid) {
    setCIDCookie(data.cid);
  }

  if (data.pid) {
    setPARTNERCookie(data.pid);
  }

  if (data.ssid) {
    setSSIDCookie(data.ssid);
  }
  if (data.ofxf) {
    setOfxFCookie(data.ofxf);
  }

  if (data.referralCode) {
    setReferralCodeCookie(data.referralCode);
  } else if (expireReferralCodeCookieWhenNotInQuery) {
    expireReferralCodeCookie();
  }
};

export const parseReferralCookies = (
  query: string,
  expireReferralCodeCookieWhenNotInQuery: boolean
): void => {
  const data = getReferrals(query);
  setReferralCookies(data, expireReferralCodeCookieWhenNotInQuery);
};
