import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { Formik, FastField } from 'formik';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@fox/components/Typography';
import TextField from '@fox/components/TextField';
import Button from '@fox/components/Button';
import Checkbox from '@components/Checkbox/Checkbox';
import Alert from '@fox/components/Alert';
import CountrySelect from '@components/CountrySelect';
import withoutAuth from '@components/WithoutAuth';
import withStore from '@components/Store/withStore/withStore';
import Currencies from '@components/Currencies';
import RegoTypeSelector from '@components/RegoTypeSelector/RegoTypeSelector';
import BusinessTypesDropdown from '@components/BusinessTypesDropdown/BusinessTypesDropdown';
import EmailInput from '@components/EmailInput/EmailInput';
import { Store, RegoTypes } from 'src/types';
import createI18n from 'src/i18n';
import { sentryLog } from 'src/util/sentryUtil';
import getBusinessTypesByLegalEntity from 'src/util/businessTypes';
import { getUrlQuery } from 'src/util/url';
import { redirect } from 'src/util/routerOperations';
import {
  pushAnalyticsEvent,
  notifyFormSubmitEvent,
} from 'src/util/analytics/googleTagManager';
import { AnalyticEventTypes } from 'src/util/analytics/types';
import getPageStepsConfig, {
  registrationRoot,
  businessPath,
} from 'src/config/pageSteps';
import userAgreementConfig from 'src/constants/userAgreementConfig';
import {
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
} from 'src/constants/validation';
import defaultCurrency from 'src/constants/defaultCurrency';
import { registrationValidationSchema } from './helpers/registrationValidationSchema';
import { parseReferralCookies } from './helpers/referrals';
import { getLegalEntity, getCountry } from './helpers/region';
import ScrollToErrorHandler from '../../components/DynamicForm/ScrollToErrorHandler';
import _isEmpty from 'lodash/isEmpty';
import ScrollToOnRender from './../../components/ScrollToOnRender/ScrollToOnRender';
import { ldIdentify } from 'src/util/launchDarklyIdentify';
import { getLocale } from 'src/util/getLocale';
import { createLead } from '@services/leadGen';
import businessTypesDictionary from 'src/constants/businessTypesDictionary';
import TransferRangeAutocomplete from '@components/TransferRange/TransferRangeAutocomplete';
import qs from 'qs';
import { useNcpFlow } from './helpers/useNcp';

const { publicRuntimeConfig } = getConfig();

interface Props extends Store {
  children?: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function subset<T>(obj: T, donor: T): any {
  const result = {};
  const keys = Object.keys(donor);
  for (const key of keys) {
    result[key] = obj[key];
  }
  return result;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    submitButtonWrapper: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '205px',
      },
    },
  };
});

const setInitialUserAgreementState = (
  locale,
  setFieldTouched,
  setFieldValue
): void => {
  setFieldTouched('userAgreement', false);
  setFieldValue('userAgreement', userAgreementConfig(locale).checked);
};

const setCurrencyPairs = (locale, setFieldValue): void => {
  const currentEx = defaultCurrency[locale];
  setFieldValue('toCurrency', currentEx.to);
  setFieldValue('fromCurrency', currentEx.from);
};

const Registration = ({
  i18n,
  setI18n,
  setBusinessDetails,
  businessDetails: { data: businessDetails },
  setShowSystemErrorMessage,
  showSystemErrorMessage: { data: showSystemErrorMessage },
}: Props): React.ReactElement => {
  const [businessSelected, setBusinessSelected] = useState(false);
  const [locale, setLocale] = useState('OFX-US');
  const classes = useStyles({});
  const router = useRouter();
  const [currCountry] = useState(businessDetails?.country || '');
  const currBusinessType = businessDetails?.businessType || '';
  const [shouldShowFormValidationError, setShouldShowFormValidationError] =
    useState(false);
  const [showAccountancyPractice, setShowAccountancyPractice] = useState(false);

  const ldClient = useLDClient();
  const {
    gcaOnboarding,
    enableCorporateOnboardingFrontEnd,
    enableNcpRegistrationFlow,
    enableDigitalKyc,
  } = useFlags();

  const [referredBy, setReferredBy] = useState('');

  useEffect(() => {
    if (ldClient) {
      // If flag is immediately on
      const allflags = ldClient.allFlags();
      // Business forms also rendered, only redirect if path is registration
      if (
        allflags['redirect-registration'] &&
        router.pathname === registrationRoot
      ) {
        window.location.replace('https://www.ofx.com/maintenance-oct-2022/');
      }
    }
  }, [ldClient]);

  useEffect(() => {
    if (ldClient) {
      const legalEntity =
        businessDetails?.locale || initialValues.locale || 'OFX-AU';
      const country = businessDetails?.country || initialValues.country || 'AU';
      const entityType = businessDetails?.businessType
        ? businessTypesDictionary[businessDetails.businessType]
        : '';
      const email = businessDetails?.email || '';
      (async () => {
        await ldIdentify(ldClient, {
          custom: {
            isoLegalEntity: legalEntity,
            country,
            entityType,
            email,
          },
        });
      })();
    }
  }, [
    ldClient,
    businessDetails?.locale,
    businessDetails?.country,
    businessDetails?.businessType,
    businessDetails?.email,
  ]);

  useEffect(() => {
    sessionStorage.removeItem('businessDetails');

    pushAnalyticsEvent(AnalyticEventTypes.step0);

    if (router.asPath.indexOf(RegoTypes.Business) !== -1) {
      setBusinessSelected(true);
    }
  }, []);

  useEffect(() => {
    const legalEntity = (businessDetails || {}).locale || 'OFX-US';
    //set initial i18n messages. Once user submits country and business type i18n is updated again
    setI18n(
      createI18n(legalEntity, null, businessDetails && businessDetails.country)
    );

    if (router.pathname === businessPath) {
      setBusinessSelected(true);
    }

    //cookie stuff
    const query = getUrlQuery();
    parseReferralCookies(query, true);

    //set referredBy in state for display message
    if (!referredBy) {
      const queryParams = qs.parse(query);
      if (
        queryParams.referredBy &&
        typeof queryParams.referredBy === 'string'
      ) {
        setReferredBy(queryParams.referredBy);
      }
    }
  }, [businessDetails]);

  const redirectToBusinessForm = (): void => {
    redirect(registrationRoot, router, businessPath, {
      shallow: true,
    });
    setBusinessSelected(true);
  };

  const redirectToPersonal = (): string =>
    (window.location.href = `${publicRuntimeConfig.secureSiteBaseUrl}/registration/personal`);

  const handleRegoTypeSelection = (selectedRego: RegoTypes): void => {
    if (selectedRego === RegoTypes.Business) {
      pushAnalyticsEvent(AnalyticEventTypes.selectBusinessRego);
      pushAnalyticsEvent(AnalyticEventTypes.businessStep1);
      redirectToBusinessForm();
    }

    if (selectedRego === RegoTypes.Personal) {
      pushAnalyticsEvent(AnalyticEventTypes.selectPersonalRego);

      redirectToPersonal();
    }
  };

  /**
   * Default transfer range is empty string for DKYC enabled countries
   * to force them to pick a value.
   * Note: we need to check what happens to GCA when DKYC is enabled
   * as you cannot pick a value, but a value is mandatory
   */
  const defaultTransferRange = '';

  interface InitialValueType {
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    businessType: string;
    fromCurrency: string;
    toCurrency: string;
    transferRange: string;
    userAgreement: boolean;
    locale: string;
    isGcaAccount: boolean;
  }

  const initialValues: InitialValueType = {
    firstName: '',
    lastName: '',
    email: '',
    country: getCountry(),
    businessType: '',
    fromCurrency: 'AUD',
    toCurrency: 'USD',
    transferRange: defaultTransferRange,
    userAgreement: true,
    locale: getLegalEntity(),
    isGcaAccount: false,
  };

  useEffect(() => {
    const currentCountry =
      businessDetails?.country || initialValues.country || getCountry();
    setShowAccountancyPractice(currentCountry === 'AU' && businessSelected);
  }, [businessDetails, initialValues, businessSelected]);

  return i18n ? (
    <>
      <Box
        height="auto"
        minHeight="100vh"
        paddingY={6}
        display="flex"
        flexDirection="column"
      >
        {showSystemErrorMessage && (
          <Box marginBottom={6}>
            <Alert severity="error" message={i18n.genericServerErrorText} />
          </Box>
        )}
        <Box marginBottom={2}>
          <Typography
            component="h1"
            variant="large"
            textAlign="center"
            fontWeight="bold"
          >
            {i18n.registrationPage.header}
          </Typography>
        </Box>
        <Box marginBottom={2}>
          <Typography variant="body2" textAlign="center">
            {i18n.registrationPage.subHeader}
          </Typography>
        </Box>
        <Box display="flex" flex={1} flexDirection="column" padding={2}>
          <RegoTypeSelector
            onRegoTypeSelection={handleRegoTypeSelection}
            i18n={{
              personal: i18n.registrationPage.personalTile,
              business: i18n.registrationPage.businessTile,
            }}
          />
          {businessSelected ? (
            <Formik
              initialValues={{
                ...initialValues,
                ...businessDetails,
              }}
              validationSchema={() =>
                registrationValidationSchema(i18n, enableNcpRegistrationFlow)
              }
              onSubmit={(values) => {
                try {
                  const updatedValues = {
                    ...businessDetails,
                    ...values,
                    locale,
                  };

                  const subsetValues = subset<InitialValueType>(
                    updatedValues,
                    initialValues
                  );

                  const changed =
                    currCountry !== values.country ||
                    currBusinessType !== values.businessType;

                  setBusinessDetails(changed ? subsetValues : updatedValues);

                  createLead({
                    first_name: values.firstName,
                    last_name: values.lastName,
                    Country_code: values.country,
                    email: values.email,
                    phone: '', //send empty string for backward compatibilty until we verify removing property won't break the SF flow
                    Annual_Transfers: values.transferRange,
                    Buy_Currencies_0: values.toCurrency,
                    Sell_Currencies_0: values.fromCurrency,
                    Leadgen_Step: 'LeadGen',
                  });

                  notifyFormSubmitEvent('registration-leadgen-form', true);

                  // GA Tracking
                  pushAnalyticsEvent(
                    AnalyticEventTypes.gcaBusinessStep1Submit,
                    businessDetails.locale,
                    {
                      userEmail: values.email,
                      customerType: RegoTypes.Business,
                      country: values.country,
                    }
                  );

                  const businessTypesByLegalEntity =
                    getBusinessTypesByLegalEntity(
                      businessDetails.locale,
                      enableCorporateOnboardingFrontEnd
                    );

                  const businessType = businessTypesByLegalEntity.find(
                    (b) => b.id === values.businessType
                  );

                  setI18n(
                    createI18n(
                      businessDetails.locale,
                      businessType.id,
                      businessDetails.country
                    )
                  );

                  const pagePaths = getPageStepsConfig(
                    businessType.abbr,
                    enableDigitalKyc
                  ).pages;

                  if (
                    useNcpFlow(
                      enableNcpRegistrationFlow,
                      businessDetails?.country
                    ) &&
                    values.accountancyPractice
                  ) {
                    router.push('/registration/paytron');
                  } else {
                    gcaOnboarding
                      ? router.push('/registration/business/products')
                      : redirect(Object.entries(pagePaths)[0][0], router);
                  }
                } catch (err) {
                  sentryLog(router.asPath, {}, err);
                  notifyFormSubmitEvent('registration-leadgen-form', false);
                }
              }}
            >
              {({
                touched,
                errors,
                values,
                handleSubmit,
                getFieldProps,
                setFieldTouched,
                setFieldValue,
                submitCount,
                isSubmitting,
                dirty,
              }) => {
                useEffect(() => {
                  (async function () {
                    if (values.country) {
                      try {
                        const locale = await getLocale(values.country);
                        const currExists =
                          businessDetails?.toCurrency &&
                          businessDetails?.fromCurrency;
                        if (!currExists) {
                          setCurrencyPairs(locale, setFieldValue);
                        }
                        setLocale(locale);

                        setInitialUserAgreementState(
                          locale,
                          setFieldTouched,
                          setFieldValue
                        );

                        setBusinessDetails({
                          ...businessDetails,
                          locale,
                          country: values.country,
                        });
                      } catch (e) {
                        setShowSystemErrorMessage(true);
                      }
                    }
                  })();
                }, [values.country]);

                useEffect(() => {
                  const isAUBusiness =
                    values.country === 'AU' && businessSelected;
                  setShowAccountancyPractice(isAUBusiness);

                  if (!isAUBusiness) {
                    setFieldTouched('accountancyPractice', false);
                    setFieldValue('accountancyPractice', false);
                  }
                }, [values.country, businessSelected]);

                const accountingCheckboxText = useMemo(() => {
                  return values.accountancyPractice ? (
                    <>
                      {i18n.registrationPage.accountancyPractice.text}
                      <br />
                      <small>
                        {i18n.registrationPage.accountancyPractice.subTitle}
                      </small>
                    </>
                  ) : (
                    i18n.registrationPage.accountancyPractice.text
                  );
                }, [values.accountancyPractice]);

                return (
                  <>
                    <ScrollToErrorHandler
                      submitCount={submitCount}
                      isSubmitting={isSubmitting}
                      errors={errors}
                      initialValues={initialValues}
                      shouldShowFormValidationError={
                        shouldShowFormValidationError
                      }
                      setShouldShowFormValidationError={
                        setShouldShowFormValidationError
                      }
                    />
                    <form onSubmit={handleSubmit}>
                      <Box marginTop={4} marginBottom={4}>
                        <Divider />
                        <ScrollToOnRender />
                        {referredBy && !referredBy.startsWith('PTRN') && (
                          <Box marginTop={2}>
                            <Typography variant="body2">
                              {`${i18n.registrationPage.referredByText} ${referredBy}`}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      {shouldShowFormValidationError && !_isEmpty(errors) && (
                        <Box>
                          <Alert
                            severity="warning"
                            message={i18n.validationMessages.globalRequired}
                          />
                        </Box>
                      )}
                      <Grid
                        id="inputSection"
                        container
                        spacing={3}
                        aria-labelledby="inputSection"
                      >
                        {showAccountancyPractice && (
                          <>
                            <Grid item xs={12} md={12}>
                              <Checkbox
                                name="accountacyPractice"
                                onChange={(e) => {
                                  setFieldTouched('accountancyPractice', true);
                                  const el = e.target as HTMLInputElement;
                                  setFieldValue(
                                    'accountancyPractice',
                                    el.checked
                                  );
                                }}
                                value={values.accountancyPractice}
                                touched={touched}
                                errors={errors}
                                fieldProps={{
                                  showCheckbox: true,
                                  checkboxTexts: [
                                    {
                                      text: accountingCheckboxText,
                                    },
                                  ],
                                }}
                                trackingId="checkbox-field"
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} md={6}>
                          <CountrySelect
                            name="country"
                            trackingId="text-country"
                            label={i18n.registrationPage.countryLabel}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <BusinessTypesDropdown
                            name="businessType"
                            trackingId="text-businessType"
                            legalEntity={(businessDetails || {}).locale}
                            label={i18n.registrationPage.businessTypeLabel}
                            isCCA={enableCorporateOnboardingFrontEnd}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FastField name="firstName">
                            {() => (
                              <TextField
                                {...getFieldProps('firstName')}
                                id="firstName"
                                trackingId="text-firstName"
                                label={i18n.registrationPage.firstNameLabel}
                                error={Boolean(
                                  touched.firstName && errors.firstName
                                )}
                                helperText={
                                  touched.firstName && errors.firstName
                                }
                                inputProps={{
                                  maxLength: FIRST_NAME_MAX_LENGTH + 1,
                                }}
                              />
                            )}
                          </FastField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FastField name="lastName">
                            {() => (
                              <TextField
                                {...getFieldProps('lastName')}
                                id="lastName"
                                trackingId="text-lastName"
                                label={i18n.registrationPage.lastNameLabel}
                                error={Boolean(
                                  touched.lastName && errors.lastName
                                )}
                                helperText={touched.lastName && errors.lastName}
                                inputProps={{
                                  maxLength: LAST_NAME_MAX_LENGTH + 1,
                                }}
                              />
                            )}
                          </FastField>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <EmailInput
                            id="registrationPageEmail"
                            trackingId="text-email"
                            label={i18n.registrationPage.emailLabel}
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                            dirty={dirty}
                          />
                        </Grid>
                      </Grid>
                      {!gcaOnboarding && (
                        <>
                          <Box marginTop={4} marginBottom={4}>
                            <Divider />
                          </Box>
                          <Currencies
                            i18n={i18n}
                            initialValues={{
                              fromCurrency: values.fromCurrency,
                              toCurrency: values.toCurrency,
                            }}
                            gcaOnboarding={gcaOnboarding}
                          />

                          <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                              <TransferRangeAutocomplete
                                name="transferRange"
                                label="What amount do you expect to transfer yearly in your local currency?"
                                onChange={(event) =>
                                  setFieldValue(
                                    'transferRange',
                                    event.target.value
                                  )
                                }
                                i18n={i18n}
                                hasError={
                                  !!(
                                    touched.transferRange &&
                                    errors.transferRange
                                  )
                                }
                                helperText={
                                  touched.transferRange && errors.transferRange
                                }
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Box marginBottom={2} marginTop={6}>
                        <Checkbox
                          name="userAgreement"
                          onChange={(e) => {
                            setFieldTouched('userAgreement', true);
                            const el = e.target as HTMLInputElement;
                            setFieldValue('userAgreement', el.checked);
                          }}
                          value={
                            touched.userAgreement
                              ? values.userAgreement
                              : userAgreementConfig(locale).checked
                          }
                          touched={touched}
                          errors={errors}
                          fieldProps={{
                            showCheckbox:
                              userAgreementConfig(locale).showCheckbox,
                            checkboxTexts: i18n.registrationPage.userAgreement,
                          }}
                          trackingId="checkbox-field"
                        />
                      </Box>
                      <Box
                        marginTop={8}
                        marginBottom={8}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box className={classes.submitButtonWrapper}>
                          <Button
                            trackingId="button-submit"
                            variant="primary"
                            type="submit"
                          >
                            <Typography variant="button1" fontWeight={600}>
                              Submit & Continue
                            </Typography>
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  </>
                );
              }}
            </Formik>
          ) : null}
        </Box>
      </Box>
    </>
  ) : null;
};

export default withoutAuth(withStore(Registration));
