const Person: React.FunctionComponent<{}> = (): React.ReactElement => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Assets/Icon/Person</title>
    <g
      id="Assets/Icon/Person"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon/Person" fillRule="nonzero" stroke="#3C3C3B" strokeWidth="2">
        <path
          d="M12,10 C13.65,10 15,8.46743295 15,6.44444444 L15,5.55555556 C15,3.53256705 13.65,2 12,2 C10.35,2 9,3.53256705 9,5.55555556 L9,6.44444444 C9,8.46743295 10.35,10 12,10 Z M7,21 L22,21 L22,19.5232104 C22,18.1959361 21.3416256,16.9550992 20.2425643,16.2109747 L15.5,13 L15.4729726,13.0154442 C13.3209439,14.2451749 10.6790561,14.2451749 8.52702743,13.0154442 L8.5,13 L3.75743575,16.2109747 C2.6583744,16.9550992 2,18.1959361 2,19.5232104 L2,21"
          id="Person"
        ></path>
      </g>
    </g>
  </svg>
);

export default Person;
