import getConfig from 'next/config';
import fetch from 'isomorphic-unfetch';

const { publicRuntimeConfig } = getConfig();

interface Response {
  Brand: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeRequest = async (url: string): Promise<any> => {
  try {
    const resp = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json());
    return resp;
  } catch (error) {
    throw error;
  }
};

export function getLegalEntityByCountry(country): Promise<Response> {
  return makeRequest(
    `${publicRuntimeConfig.apiBaseUrl}/RefData.ApiService/brand/${country}`
  );
}
