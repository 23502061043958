import { useRouter } from 'next/router';
import BusinessIcon from '@fox/components/Icons/Business';
import PersonalIcon from '@fox/components/Icons/Person';
import Tile from '@fox/components/Tiles';
import { RegoTypes } from 'src/types';

interface TitleText {
  title: string;
  subTitle: string;
}

interface Props {
  onRegoTypeSelection: (selectedRego: RegoTypes) => void;
  i18n: {
    personal: TitleText;
    business: TitleText;
  };
}

const RegoTypeSelector = ({
  onRegoTypeSelection,
  i18n: { personal, business },
}: Props): React.ReactElement<Props> => {
  const router = useRouter();

  return (
    <Tile
      name="rego"
      handleClick={onRegoTypeSelection}
      options={[
        {
          title: personal.title,
          subTitle: personal.subTitle,
          icon: <PersonalIcon />,
          value: RegoTypes.Personal,
          trackingId: 'radio-selected-' + RegoTypes.Personal,
          defaultChecked: router.asPath.indexOf(RegoTypes.Personal) !== -1,
        },
        {
          title: business.title,
          subTitle: business.subTitle,
          icon: <BusinessIcon />,
          value: RegoTypes.Business,
          trackingId: 'radio-selected-' + RegoTypes.Business,
          defaultChecked: router.asPath.indexOf(RegoTypes.Business) !== -1,
        },
      ]}
    />
  );
};

export default RegoTypeSelector;
