const Business: React.FunctionComponent<{}> = (): React.ReactElement => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Assets/Icon/Business</title>
    <g
      id="Assets/Icon/Business"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon/Legal" fillRule="nonzero" stroke="#3C3C3B" strokeWidth="2">
        <path
          d="M17,9 L17,3 C17,2.44771525 16.5522847,2 16,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,21 C2,21.5522847 2.44771525,22 3,22 L10,22 M6,6 L13,6 M6,10 L7,10 M6,14 L7,14 M6,18 L7,18"
          id="Combined-Shape"
        ></path>
        <path
          d="M14,22 L22,22 L22,11 C22,10.4477153 21.5522847,10 21,10 L11,10 C10.4477153,10 10,10.4477153 10,11 L10,22 M14,14 L18,14 M14,18 L18,18"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
);

export default Business;
