import React from 'react';
import { Field } from 'formik';
import Autocomplete from '@fox/components/Autocomplete';
import getBusinessTypesByLegalEntity from 'src/util/businessTypes';
import _find from 'lodash/find';

const getBusinessTypes = (
  legalEntity,
  isCCA
): { label: string; value: string }[] =>
  React.useMemo(() => {
    return getBusinessTypesByLegalEntity(legalEntity, isCCA).reduce(
      (result, item) => [
        ...result,
        {
          value: item.id,
          label: item.name,
        },
      ],
      []
    );
  }, [legalEntity]);

interface Props {
  name: string;
  trackingId: string;
  legalEntity: string;
  label: string;
  isCCA?: boolean;
}

const BusinessTypesDropdown = ({
  name,
  legalEntity,
  label,
  trackingId,
  isCCA = false,
}: Props): React.ReactElement => (
  <Field name={name}>
    {({ form, field }) => {
      const defaultValue = _find(getBusinessTypes(legalEntity, isCCA), {
        value: field.value,
      });
      return (
        <Autocomplete
          defaultValue={defaultValue}
          {...field}
          trackingId={trackingId}
          label={label}
          options={getBusinessTypes(legalEntity, isCCA)}
          error={form.touched[name] && !!form.errors[name]}
          helperText={form.touched[name] && form.errors[name]}
        />
      );
    }}
  </Field>
);

export default BusinessTypesDropdown;
