import { getRegionCookie } from './cookies';
import { LegalEntities } from 'src/types';

export const getLegalEntity = (): LegalEntities => {
  const { region } = getRegionCookie();
  return LegalEntities[region];
};

export const getCountry = (): string => {
  const { region } = getRegionCookie();
  return region;
};
