import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const ScrollToOnRender: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const guid = uuidv4();
  const id = `scrolltoonrender-${guid}`;

  useEffect(() => {
    if (!scrolled) {
      const anchor = document.getElementById(id);

      if (anchor) {
        const space = anchor.offsetTop;
        window.scrollTo({
          left: 0,
          top: space,
          behavior: 'smooth',
        });
      }

      setScrolled(true);
    }
  }, [scrolled]);

  return <div id={id}></div>;
};

export default ScrollToOnRender;
